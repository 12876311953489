var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-4"},[_vm._v(" My Transactions ")]),(_vm.accountConnected)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-combobox',{staticClass:"mb-4",attrs:{"items":_vm.projects,"label":"Projects","placeholder":"Select projects to filter transactions","hide-details":"auto","item-text":"title","multiple":"","outlined":"","dense":"","small-chips":""},model:{value:(_vm.selectedProjects),callback:function ($$v) {_vm.selectedProjects=$$v},expression:"selectedProjects"}})],1),_c('v-col',{staticClass:"d-none d-md-block text-right"},[_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1)],1)],1):_vm._e(),(_vm.accountConnected)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"loading":_vm.loading,"loading-text":"Loading transactions...","no-data-text":"No transactions found. Make sure you've selected the right account and network. Transactions older than 1000 blocks are not shown."},scopedSlots:_vm._u([{key:"item.hash",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"hash text-decoration-none",attrs:{"target":"_blank","href":((_vm.$store.getters.explorerUrlFor(_vm.$store.getters.chainId)) + "/tx/" + (item.hash))}},[_vm._v(_vm._s(item.hash))])]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"hash text-decoration-none",attrs:{"target":"_blank","href":((_vm.$store.getters.explorerUrlFor(_vm.$store.getters.chainId)) + "/address/" + (item.from))}},[_vm._v(_vm._s(item.from))])]}},{key:"item.block",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"hash text-decoration-none",attrs:{"target":"_blank","href":((_vm.$store.getters.explorerUrlFor(_vm.$store.getters.chainId)) + "/block/" + (item.block))}},[_vm._v(_vm._s(item.block))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.value)))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.type === 'Inbound' ? 'success' : 'primary'}},[_vm._v(_vm._s(item.type))])]}}],null,true)}):_vm._e(),(!_vm.accountConnected)?_c('v-alert',{attrs:{"type":"info","prominent":"","outlined":"","text":""}},[_c('p',{staticClass:"mb-0"},[_vm._v(" Connect your Ethereum wallet to view your transactions. ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }