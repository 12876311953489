<template>
  <div>
    <h1 class="mb-4">
      My Transactions
    </h1>
    <v-row v-if="accountConnected">
      <v-col cols="12" md="6">
        <v-combobox
          v-model="selectedProjects"
          :items="projects"
          label="Projects"
          placeholder="Select projects to filter transactions"
          hide-details="auto"
          item-text="title"
          class="mb-4"
          multiple
          outlined
          dense
          small-chips
        ></v-combobox>
      </v-col>
      <v-col class="d-none d-md-block text-right">
        <v-btn
          fab
          small
          @click="refresh"
        >
          <v-icon>
            {{ icons.mdiRefresh }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      v-if="accountConnected"
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading transactions..."
      no-data-text="No transactions found. Make sure you've selected the right account and network. Transactions older than 1000 blocks are not shown."
    >
      <template #[`item.hash`]="{item}">
        <a class="hash text-decoration-none" target="_blank" :href="`${$store.getters.explorerUrlFor($store.getters.chainId)}/tx/${item.hash}`">{{ item.hash }}</a>
      </template>

      <template #[`item.from`]="{item}">
        <a class="hash text-decoration-none" target="_blank" :href="`${$store.getters.explorerUrlFor($store.getters.chainId)}/address/${item.from}`">{{ item.from }}</a>
      </template>

      <template #[`item.block`]="{item}">
        <a class="hash text-decoration-none" target="_blank" :href="`${$store.getters.explorerUrlFor($store.getters.chainId)}/block/${item.block}`">{{ item.block }}</a>
      </template>

      <template #[`item.value`]="{item}">
        <span>{{ item.value | toCurrency }}</span>
      </template>

      <template #[`item.type`]="{item}">
        <v-chip small :color="item.type === 'Inbound' ? 'success' : 'primary'">{{ item.type }}</v-chip>
      </template>
    </v-data-table>

    <v-alert
      v-if="!accountConnected"
      type="info"
      prominent
      outlined
      text
    >
      <p class="mb-0">
        Connect your Ethereum wallet to view your transactions.
      </p>
    </v-alert>
  </div>
</template>

<script>
import { mdiRefresh } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiRefresh,
      },
    }
  },
  data() {
    return {
      headers: [
        { text: 'Transaction Hash', value: 'hash' },
        { text: 'From', value: 'from' },
        { text: 'Block', value: 'block' },
        { text: 'Age', value: 'age' },
        { text: 'Value', value: 'value' },
        { text: 'Type', value: 'type' },
      ],
      loading: false,
      accountConnected: false,
      selectedProjects: this.$store.getters.transactionsFilters,
    }
  },
  computed: {
    items() {
      return this.$store.getters.transactionsItems
    },
    loaded() {
      return this.$store.getters.transactionsLoaded
    },
    projects() {
      return this.$store.getters.projects
    },
  },
  watch: {
    '$store.state.wallet': function(wallet) {
      this.refresh()
      if (wallet) {
        this.accountConnected = true
      } else {
        this.accountConnected = false
      }
    },
    '$store.state.chainId': function(chainId) {
      this.refresh()
    },
    selectedProjects: function(val) {
      this.$store.commit('transactionsFilters', val)
      this.refresh()
    },
  },
  mounted() {
    if (this.$store.getters.wallet) {
      this.accountConnected = true
      this.load()
    }
  },
  methods: {
    load() {
      var stablecoinAddress = this.$store.getters.getStablecoinAddress
      if (!this.loaded && !this.loading && stablecoinAddress) {
        this.loading = true
        setTimeout(() => {
          var wallet = this.$store.getters.wallet
          var instance = new this.$web3.eth.Contract(this.$ERC20ABI, stablecoinAddress)

          this.$web3.eth.getBlockNumber().then((latestBlock) => {
            var filterTo = this.selectedProjects.map((project) => { return project.address })
            if (filterTo.length === 0) {
              filterTo = this.projects.map((project) => { return project.address })
            }

            var filterFrom = filterTo
            filterFrom.push(wallet)

            instance.getPastEvents('Transfer', {
              fromBlock: latestBlock - 1000, // Polygon only allows getting events from last 1000 blocks
              filter: { from: filterFrom, to: filterTo },
            }).then((events) => {
              var items = events.map((event) => {
                return {
                  hash: event.transactionHash,
                  from: event.returnValues.from,
                  block: event.blockNumber,
                  age: this.blockAge(latestBlock - event.blockNumber),
                  value: Number(this.$fromWei(event.returnValues.value, 6)),
                  type: event.returnValues.from === wallet ? 'Outbound' : 'Inbound',
                }
              }).reverse()
              this.$store.commit('transactionsItems', items)
              this.$store.commit('transactionsLoaded')
              this.loading = false
            }).catch((e) => {
              this.loading = false
              console.error(e)
            })
          })
        }, 10)
      }
    },
    refresh() {
      this.$store.commit('transactionsClear')
      this.load()
    },
    blockAge(blocks) {
      var days = (blocks * 2.2) / (60 * 60 * 24) // Use Polygon average blocktime of 2.2 seconds
      var days_rounded = Math.floor(days)
      var hours_rounded = Math.round((days - days_rounded) * 24)
      var ageString = days_rounded < 1 ? `${hours_rounded} hours` : `${days_rounded} days`
      return ageString
    },
  },
}
</script>

<style scoped>
  .hash {
    max-width: 150px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
